export const Setting = {
  CARDS_NUMBER: 8,
};

export const AppRoute = {
  ROOT: '/',
  LOGIN: '/login',
  FAVORITES: '/mylist',
  MOVIES: '/films',
  ADD_REVIEW: '/review',
  PLAYER: '/player',
  NOT_FOUND: '/not-found',
};

export const APIRoute = {
  GENRES: '/movies/genres',
  MOVIES: '/movies',
  PROMO: '/movies/promo/',
  LOGIN: '/token',
  LOGOUT: '/logout',
  REVIEWS: '/comments',
  FAVORITE: '/favorites',
};

export enum AuthorizationStatus {
  Auth = 'AUTH',
  NoAuth = 'NO_AUTH',
  Unknown = 'UNKNOWN',
}

export const MovieTabNames = {
  OVERVIEW: 'Overview',
  DETAILS: 'Details',
  REVIEWS: 'Reviews',
};

export enum ActionType {
  LoadGenres = 'MOVIES/LOAD_GENRES',
  ClearGenres = 'MOVIES/CLEAR_GENRES',
  SelectGenre = 'MAIN/SELECT_GENRE',
  ClearSelectedGenre = 'MOVIES/CLEAR_SELECTED_GENRE',
  LoadMovies = 'MOVIES/LOAD_MOVIES',
  ClearMovies = 'MOVIES/CLEAR_MOVIES',
  SetMoviesOffset = 'MOVIES/SET_MOVIES_OFFSET',
  ClearMoviesOffset = 'MOVIES/CLEAR_MOVIES_OFFSET',
  SetMoviesTotal = 'MOVIES/SET_MOVIES_TOTAL',
  ClearMoviesTotal = 'MOVIES/CLEAR_MOVIES_TOTAL',
  LoadPromo = 'MOVIES/LOAD_PROMO',
  LoadMovieById = 'MOVIES/LOAD_MOVIE_BY_ID',
  ClearMovieById = 'MOVIES/CLEAR_MOVIE',
  LoadReviews = 'MOVIES/LOAD_REVIEWS',
  PostReview = 'MOVIES/POST_REVIEW',
  PostError = 'MOVIES/POST_REVIEW_ERROR',
  ClearPostReviewStatus = 'MOVIES/CLEAR_POST_REVIEW_STATUS',
  ClearPostReviewError = 'MOVIES/CLEAR_POST_REVIEW_ERROR',
  GetFavoriteMovies = 'FAVORITE/LOAD_FAVORITE_MOVIES',
  ClearFavoriteMovies = 'FAVORITE/CLEAR_FAVORITE_MOVIES',
  LoadFavoriteMovieStatus = 'FAVORITE/LOAD_FAVORITE_MOVIE_STATUS',
  ClearFavoriteMovieStatus = 'FAVORITE/CLEAR_FAVORITE_MOVIE_STATUS',
  LoadSimilarMovies = 'MOVIES/LOAD_SIMILAR_MOVIES',
  ClearSimilarMovies = 'MOVIES/CLEAR_SIMILAR_MOVIES',
  RequireAuthorization = 'USER/REQUIRE_AUTHORIZATION',
  RequireLogout = 'USER/REQUIRE_LOGOUT',
  RedirectToRoute = 'USER/REDIRECT',
}

export const HeaderClass = {
  MOVIE_CARD: 'movie-card__head',
  USER_PAGE: 'user-page__head',
  NO_CLASS: '',
};

export enum PageScreen {
  Main,
  Movie,
  Login,
  Favorites,
  AddReview,
}

export const NUMBER_OF_SIMILAR_MOVIES = 4;

export const MOVIES_LIMIT = 8;
