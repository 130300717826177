import { createAction } from '@reduxjs/toolkit';
import { ActionType, AuthorizationStatus } from '../../const/const';
import { MovieType, ReviewType } from '../../types/types';

export const loadGenres = createAction(ActionType.LoadGenres, (genres: string[]) => ({
  payload: {
    genres,
  },
}));

export const clearGenres = createAction(ActionType.ClearGenres);

export const selectGenre = createAction(ActionType.SelectGenre, (genre: string) => ({
  payload: {
    genre,
  },
}));

export const clearSelectedGenre = createAction(ActionType.ClearSelectedGenre);

export const loadMovies = createAction(ActionType.LoadMovies, (movies: MovieType[]) => ({
  payload: {
    movies,
  },
}));

export const clearMovies = createAction(ActionType.ClearMovies);

export const setMoviesOffset = createAction(ActionType.SetMoviesOffset, (offset: number) => ({
  payload: {
    offset,
  },
}));

export const clearMoviesOffset = createAction(ActionType.ClearMoviesOffset);

export const setMoviesTotal = createAction(ActionType.SetMoviesTotal, (total: number) => ({
  payload: {
    total,
  },
}));

export const clearMoviesTotal = createAction(ActionType.ClearMoviesTotal);

export const loadPromo = createAction(ActionType.LoadPromo, (promo: MovieType) => ({
  payload: {
    promo,
  },
}));

export const loadMovieById = createAction(ActionType.LoadMovieById, (movie: MovieType) => ({
  payload: {
    movie,
  },
}));

export const clearMovieById = createAction(ActionType.ClearMovieById);

export const loadReviews = createAction(ActionType.LoadReviews, (reviews: ReviewType[]) => ({
  payload: {
    reviews,
  },
}));

export const postReviewAction = createAction(ActionType.PostReview, (reviews: ReviewType[]) => ({
  payload: {
    reviews,
  },
}));

export const postReviewError = createAction(ActionType.PostError);

export const clearPostReviewStatus = createAction(ActionType.ClearPostReviewStatus);

export const clearPostReviewError = createAction(ActionType.ClearPostReviewError);

export const loadFavoriteMovies = createAction(
  ActionType.GetFavoriteMovies,
  (favoriteMovies: MovieType[]) => ({
    payload: {
      favoriteMovies,
    },
  })
);

export const clearFavoriteMovies = createAction(ActionType.ClearFavoriteMovies);

export const loadFavoriteMovieStatus = createAction(
  ActionType.LoadFavoriteMovieStatus,
  (isFavorite: boolean) => ({
    payload: {
      isFavorite,
    },
  })
);

export const clearFavoriteMovieStatus = createAction(ActionType.ClearFavoriteMovieStatus);

export const requireAuthorization = createAction(
  ActionType.RequireAuthorization,
  (authStatus: AuthorizationStatus, email?: string, avatarUrl?: string) => ({
    payload: {
      authStatus,
      email,
      avatarUrl,
    },
  })
);

export const loadSimilarMovies = createAction(
  ActionType.LoadSimilarMovies,
  (movies: MovieType[]) => ({
    payload: {
      movies,
    },
  })
);

export const clearSimilarMovies = createAction(ActionType.ClearSimilarMovies);

export const requireLogout = createAction(ActionType.RequireLogout);

export const redirectToRoute = createAction(ActionType.RedirectToRoute, (url: string) => ({
  payload: {
    url,
  },
}));
