import { MovieType } from '../../types/types';
import MovieListEmpty from '../movie-list-empty/movie-list-empty';
import MovieCard from '../movie-card/movie-card';
import { useSelector } from 'react-redux';
import { getMoviesLoadingStatus } from '../../store/reducers/movies/movies-selectors';
import LoadingScreen from '../loading-screen/loading-screen';

type MovieListProps = {
  movies: MovieType[];
  moviesLoaded: boolean;
};

function MovieList({ movies, moviesLoaded }: MovieListProps): JSX.Element {
  if (!moviesLoaded) {
    return <LoadingScreen />;
  }

  if (movies.length === 0) {
    return <MovieListEmpty />;
  }

  return (
    <div className="catalog__movies-list">
      {movies.map((movie) => (
        <MovieCard movie={movie} key={movie.id} />
      ))}
    </div>
  );
}

export default MovieList;
