import { MouseEvent } from 'react';
import { useSelector } from 'react-redux';
import {
  getMoviesOffset,
  getMoviesTotal,
  getSelectedGenre,
} from '../../store/reducers/movies/movies-selectors';
import { useAppDispatch } from '../../hooks/hooks';
import { fetchMovies } from '../../store/actions/api-actions';
import { setMoviesOffset } from '../../store/actions/actions';
import { MOVIES_LIMIT } from '../../const/const';

type ShowMoreProps = {};

function ShowMore({}: ShowMoreProps): JSX.Element {
  const moviesOffset = useSelector(getMoviesOffset);
  const moviesTotal = useSelector(getMoviesTotal);
  const dispatch = useAppDispatch();
  const selectedGenre = useSelector(getSelectedGenre);

  const handleShowMoreClick = (evt: MouseEvent<HTMLElement>) => {
    evt.preventDefault();
    if (moviesTotal <= moviesOffset) {
      return;
    }
    dispatch(fetchMovies(selectedGenre, moviesOffset));
    dispatch(setMoviesOffset(moviesOffset + MOVIES_LIMIT));
  };

  return (
    <div className="catalog__more">
      <button className="catalog__button" type="button" onClick={handleShowMoreClick}>
        Show more
      </button>
    </div>
  );
}

export default ShowMore;
