import { useSelector } from 'react-redux';
import { HeaderClass, PageScreen } from '../../const/const';
import {
  getFavoriteMovies,
  getFavoriteMoviesLoadingStatus,
} from '../../store/reducers/movies/movies-selectors';
import Footer from '../footer/footer';
import Header from '../header/header';
import MovieList from '../movie-list/movie-list';
import { useEffect } from 'react';
import { useAppDispatch } from '../../hooks/hooks';
import { fetchFavoriteMovies } from '../../store/actions/api-actions';
import LoadingScreen from '../loading-screen/loading-screen';
import { clearFavoriteMovies } from '../../store/actions/actions';

function Favorites(): JSX.Element {
  const favoriteMovies = useSelector(getFavoriteMovies);
  const favoriteMoviesLoaded = useSelector(getFavoriteMoviesLoadingStatus);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchFavoriteMovies());
    return () => {
      dispatch(clearFavoriteMovies());
    };
  }, []);

  if (!favoriteMoviesLoaded) {
    return <LoadingScreen />;
  }

  return (
    <div className="user-page">
      <Header
        headerClass={HeaderClass.USER_PAGE}
        page={PageScreen.Favorites}
        isWithUserNavigation
      />

      <section className="catalog">
        <h2 className="catalog__title visually-hidden">Catalog</h2>
        <MovieList movies={favoriteMovies} moviesLoaded={favoriteMoviesLoaded} />
      </section>

      <Footer />
    </div>
  );
}

export default Favorites;
