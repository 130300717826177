import { createSelector } from 'reselect';
import { NUMBER_OF_SIMILAR_MOVIES } from '../../../const/const';
import { MovieType, State } from '../../../types/types';
import { NameSpace } from '../root-reducer';

export const getGenres = (state: State): string[] => state[NameSpace.movies].genres;
export const getGenresLoadingStatus = (state: State): boolean =>
  state[NameSpace.movies].genresLoaded;

export const getSelectedGenre = (state: State): string => state[NameSpace.movies].selectedGenre;

export const getMovies = (state: State): MovieType[] => state[NameSpace.movies].movies;
export const getMoviesLoadingStatus = (state: State): boolean =>
  state[NameSpace.movies].moviesLoaded;

export const getMoviesOffset = (state: State): number => state[NameSpace.movies].moviesOffset;
export const getMoviesTotal = (state: State): number => state[NameSpace.movies].moviesTotal;

export const getPromo = (state: State): MovieType | undefined => state[NameSpace.movies].promo;
export const getPromoLoadingStatus = (state: State): boolean =>
  state[NameSpace.movies].isPromoLoaded;
export const getMovieById = (state: State): MovieType | undefined => state[NameSpace.movies].movie;

export const getFavoriteMovies = (state: State): MovieType[] =>
  state[NameSpace.movies].favoriteMovies;
export const getFavoriteMoviesLoadingStatus = (state: State): boolean =>
  state[NameSpace.movies].favoriteMoviesLoaded;
export const getMovieFavoriteStatus = (state: State): boolean =>
  state[NameSpace.movies].isMovieFavorite;

export const getSimilarMovies = (state: State): MovieType[] =>
  state[NameSpace.movies].similarMovies;
export const getSimilarMoviesLoadingStatus = (state: State): boolean =>
  state[NameSpace.movies].similarMoviesLoaded;

//Example with Reselect
// const getMovieGenre = (_state: State, genre: string): string => genre;

// export const getSimilarMoviesByGenre = createSelector(getMovies, getMovieGenre, (movies, genre) =>
//   movies
//     .slice()
//     .filter((mov) => mov.genre === genre)
//     .slice(0, NUMBER_OF_SIMILAR_MOVIES)
// );

// Reselect. Первые два аргумента - зависимости.
// Если они не меняются, то третья функция не запустится
// и фильтрация не применится, т.к. результат функции мемоизирован.
