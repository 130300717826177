import { AppRoute, AuthorizationStatus } from '../../const/const';
import { ThunkActionResult } from '../../types/types';
import { redirectToRoute } from './actions';
import { addToFavorites, removeFromFavorites } from './api-actions';

export const handleFavoriteClickAction =
  (status: AuthorizationStatus, isFavorite: boolean, id: number): ThunkActionResult =>
  async (dispatch, _getState): Promise<void> => {
    if (status !== AuthorizationStatus.Auth) {
      dispatch(redirectToRoute(AppRoute.LOGIN));
    } else if (isFavorite) {
      dispatch(removeFromFavorites(id));
    } else {
      dispatch(addToFavorites(id));
    }
  };
