import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import {
  APIRoute,
  AppRoute,
  AuthorizationStatus,
  MOVIES_LIMIT,
  NUMBER_OF_SIMILAR_MOVIES,
} from '../../const/const';
import { dropToken, saveToken } from '../../services/token';
import {
  AuthInfo,
  MovieType,
  PostReviewType,
  ReviewType,
  State,
  ThunkActionResult,
} from '../../types/types';
import {
  clearFavoriteMovieStatus,
  clearGenres,
  clearMovies,
  clearMoviesOffset,
  clearSelectedGenre,
  loadFavoriteMovieStatus,
  loadFavoriteMovies,
  loadGenres,
  loadMovieById,
  loadMovies,
  loadPromo,
  loadReviews,
  loadSimilarMovies,
  postReviewAction,
  postReviewError,
  redirectToRoute,
  requireAuthorization,
  requireLogout,
  selectGenre,
  setMoviesTotal,
} from './actions';
import { NameSpace } from '../reducers/root-reducer';

export const fetchGenres =
  (): ThunkActionResult =>
  async (dispatch, _getState, api): Promise<void> => {
    try {
      const { data } = await api.get<string[]>(`${APIRoute.GENRES}/`);
      if (data) {
        dispatch(loadGenres(data));
      }
    } catch (error) {
      handleError(error);
    }
  };

export const fetchMovies =
  (genre: string, skip: number): ThunkActionResult =>
  async (dispatch, _getState, api): Promise<void> => {
    try {
      const query =
        genre !== 'All'
          ? `?genre=${genre}&skip=${skip}&limit=${MOVIES_LIMIT}`
          : `?skip=${skip}&limit=${MOVIES_LIMIT}`;

      const { data, headers } = await api.get<MovieType[]>(`${APIRoute.MOVIES}/${query}`);
      const total = headers['x-total-count'];

      if (total) {
        dispatch(setMoviesTotal(parseInt(total)));
      }
      if (data) {
        dispatch(loadMovies(data));
      }
    } catch (error) {
      handleError(error);
    }
  };

export const fetchPromo =
  (): ThunkActionResult =>
  async (dispatch, _getState, api): Promise<void> => {
    try {
      const { data } = await api.get<MovieType>(APIRoute.PROMO);
      dispatch(loadPromo(data));
    } catch (error) {
      handleError(error);
    }
  };

export const fetchMovieById =
  (id: number): ThunkActionResult =>
  async (dispatch, _getState, api): Promise<void> => {
    try {
      const { data } = await api.get<MovieType>(`${APIRoute.MOVIES}/${id}`);
      dispatch(loadMovieById(data));
    } catch {
      dispatch(redirectToRoute(AppRoute.NOT_FOUND));
    }
  };

export const fetchReviews =
  (id: number): ThunkActionResult =>
  async (dispatch, _getState, api): Promise<void> => {
    try {
      const { data } = await api.get<ReviewType[]>(`${APIRoute.REVIEWS}/${id}`);
      dispatch(loadReviews(data));
    } catch (error) {
      handleError(error);
    }
  };

export const postReview =
  (id: number, review: PostReviewType): ThunkActionResult =>
  async (dispatch, _getState, api): Promise<void> => {
    try {
      const { data } = await api.post<ReviewType[]>(`${APIRoute.REVIEWS}/${id}`, review);
      dispatch(postReviewAction(data));
      // toast.info(POST_REVIEW_SUCCESS_MESSAGE);
    } catch (error) {
      handleError(error);
      dispatch(postReviewError());
    }
  };

export const fetchFavoriteMovies =
  (): ThunkActionResult =>
  async (dispatch, _getState, api): Promise<void> => {
    try {
      const { data } = await api.get<MovieType[]>(`${APIRoute.FAVORITE}/`);
      if (data) {
        dispatch(loadFavoriteMovies(data));
      }
    } catch (error) {
      handleError(error);
    }
  };

export const addToFavorites =
  (id: number): ThunkActionResult =>
  async (dispatch, _getState, api): Promise<void> => {
    try {
      await api.post<MovieType>(`${APIRoute.FAVORITE}/${id}`);
      dispatch(fetchFavoriteStatus(id));
    } catch (error) {
      handleError(error);
    }
  };

export const removeFromFavorites =
  (id: number): ThunkActionResult =>
  async (dispatch, _getState, api): Promise<void> => {
    try {
      await api.delete<MovieType>(`${APIRoute.FAVORITE}/${id}`);
      dispatch(fetchFavoriteStatus(id));
    } catch (error) {
      handleError(error);
    }
  };

export const fetchFavoriteStatus =
  (id: number): ThunkActionResult =>
  async (dispatch, _getState, api): Promise<void> => {
    try {
      const { data } = await api.get(`${APIRoute.FAVORITE}/${id}`);
      dispatch(loadFavoriteMovieStatus(data['is_favorite']));
    } catch (error) {
      handleError(error);
    }
  };

export const fetchSimilarMovies =
  (id: number): ThunkActionResult =>
  async (dispatch, _getState, api): Promise<void> => {
    try {
      const { data } = await api.get<MovieType[]>(
        `${APIRoute.MOVIES}/${id}/similar/?limit=${NUMBER_OF_SIMILAR_MOVIES}`
      );
      if (data) {
        dispatch(loadSimilarMovies(data));
      }
    } catch (error) {
      handleError(error);
    }
  };

export const login =
  (email: string, password: string): ThunkActionResult =>
  async (dispatch, _getState, api): Promise<void> => {
    try {
      const { data } = await api.post<AuthInfo>(
        APIRoute.LOGIN,
        `username=${email}&password=${password}`
      );
      saveToken(data.access_token);
      dispatch(requireAuthorization(AuthorizationStatus.Auth, email, data.avatar_url));
      dispatch(redirectToRoute(AppRoute.ROOT));
    } catch (error) {
      handleError(error);
    }
  };

export const checkAuth =
  (): ThunkActionResult =>
  async (dispatch, _getState, api): Promise<void> => {
    try {
      const { data } = await api.get(APIRoute.LOGIN);
      if (data) {
        dispatch(requireAuthorization(AuthorizationStatus.Auth, data.email, data.avatar_url));
      }
    } catch (error) {
      handleError(error);
    }
  };

export const logout =
  (): ThunkActionResult =>
  async (dispatch, _getState, api): Promise<void> => {
    try {
      await api.delete(APIRoute.LOGOUT);
      dropToken();
      dispatch(requireLogout());
      dispatch(resetMovies());
    } catch (error) {
      handleError(error);
    }
  };

export const resetMovies =
  (): ThunkActionResult =>
  async (dispatch, _getState, _api): Promise<void> => {
    dispatch(clearSelectedGenre());
    dispatch(clearMoviesOffset());
    dispatch(clearGenres());
    dispatch(clearMovies());
    dispatch(clearFavoriteMovieStatus());
    dispatch(fetchGenres());

    window.location.reload(); //TODO: potential enhancement
  };

const handleError = (err: unknown) => {
  if (axios.isAxiosError(err)) {
    const error = err as AxiosError<ServerError>;
    toast.info(error.response?.data.error);
  }
};

type ServerError = {
  error: string;
};
