import { createReducer } from '@reduxjs/toolkit';
import { MovieState } from '../../../types/types';
import { adaptMovieToClient } from '../../../utils/utils';
import {
  clearFavoriteMovieStatus,
  clearFavoriteMovies,
  clearGenres,
  clearMovieById,
  clearMovies,
  clearMoviesOffset,
  clearMoviesTotal,
  clearSelectedGenre,
  clearSimilarMovies,
  loadFavoriteMovieStatus,
  loadFavoriteMovies,
  loadGenres,
  loadMovieById,
  loadMovies,
  loadPromo,
  loadSimilarMovies,
  selectGenre,
  setMoviesOffset,
  setMoviesTotal,
} from '../../actions/actions';
import { MOVIES_LIMIT } from '../../../const/const';

export const initialState: MovieState = {
  genres: ['All'],
  genresLoaded: false,
  selectedGenre: 'All',
  movies: [],
  moviesLoaded: false,
  moviesOffset: MOVIES_LIMIT,
  moviesTotal: 0,
  promo: undefined,
  isPromoLoaded: false,
  movie: undefined,
  isMovieLoaded: false,
  favoriteMovies: [],
  favoriteMoviesLoaded: false,
  isMovieFavorite: false,
  similarMovies: [],
  similarMoviesLoaded: false,
};

export const moviesData = createReducer(initialState, (builder) => {
  builder
    .addCase(loadGenres, (state, action) => {
      state.genres = [...state.genres, ...action.payload.genres];
      state.genresLoaded = true;
    })
    .addCase(clearGenres, (state) => {
      state.genres = initialState.genres;
      state.genresLoaded = false;
    })
    .addCase(selectGenre, (state, action) => {
      state.selectedGenre = action.payload.genre;
    })
    .addCase(clearSelectedGenre, (state, action) => {
      state.selectedGenre = initialState.selectedGenre;
    })
    .addCase(loadMovies, (state, action) => {
      state.movies = [
        ...state.movies,
        ...action.payload.movies.map((item) => adaptMovieToClient(item)),
      ];
      state.moviesLoaded = true;
    })
    .addCase(clearMovies, (state, action) => {
      state.movies = initialState.movies;
      state.moviesLoaded = initialState.moviesLoaded;
    })
    .addCase(setMoviesOffset, (state, action) => {
      state.moviesOffset = action.payload.offset;
    })
    .addCase(clearMoviesOffset, (state, action) => {
      state.moviesOffset = initialState.moviesOffset;
    })
    .addCase(setMoviesTotal, (state, action) => {
      state.moviesTotal = action.payload.total;
    })
    .addCase(clearMoviesTotal, (state, action) => {
      state.moviesTotal = initialState.moviesTotal;
    })
    .addCase(loadPromo, (state, action) => {
      state.promo = adaptMovieToClient(action.payload.promo);
      state.isPromoLoaded = true;
    })
    .addCase(loadMovieById, (state, action) => {
      state.movie = adaptMovieToClient(action.payload.movie);
      state.isMovieLoaded = true;
    })
    .addCase(clearMovieById, (state, action) => {
      state.movie = initialState.movie;
      state.isMovieLoaded = initialState.isMovieLoaded;
    })
    .addCase(loadFavoriteMovies, (state, action) => {
      state.favoriteMovies = action.payload.favoriteMovies.map((item) => adaptMovieToClient(item));
      state.favoriteMoviesLoaded = true;
    })
    .addCase(clearFavoriteMovies, (state) => {
      state.favoriteMovies = initialState.favoriteMovies;
      state.favoriteMoviesLoaded = initialState.favoriteMoviesLoaded;
    })
    .addCase(loadFavoriteMovieStatus, (state, action) => {
      state.isMovieFavorite = action.payload.isFavorite;
    })
    .addCase(clearFavoriteMovieStatus, (state, action) => {
      state.isMovieFavorite = initialState.isMovieFavorite;
    })
    .addCase(loadSimilarMovies, (state, action) => {
      state.similarMovies = action.payload.movies.map((item) => adaptMovieToClient(item));
      state.similarMoviesLoaded = true;
    })
    .addCase(clearSimilarMovies, (state, action) => {
      state.similarMovies = initialState.movies;
      state.similarMoviesLoaded = initialState.moviesLoaded;
    });
});
