import { Fragment } from 'react';
import { MovieType } from '../../../types/types';

type DetailsProps = {
  movie: MovieType;
}

function Details({movie}:DetailsProps): JSX.Element {
  const {director, starring, runTime, genre, released} = movie;

  const duration = runTime > 60 ? `${Math.floor(runTime / 60)}h ${runTime % 60}m` : `${runTime}m`;

  return (
    <div className="movie-card__text movie-card__row">
      <div className="movie-card__text-col">
        <p className="movie-card__details-item">
          <strong className="movie-card__details-name">Director</strong>
          <span className="movie-card__details-value">{director}</span>
        </p>
        <p className="movie-card__details-item">
          <strong className="movie-card__details-name">Starring</strong>
          <span className="movie-card__details-value">
            {starring.map((star) =>
              (
                <Fragment key={1}>
                  {star.name}, <br/>
                </Fragment>
              )
            )}
          </span>
        </p>
      </div>

      <div className="movie-card__text-col">
        <p className="movie-card__details-item">
          <strong className="movie-card__details-name">Run Time</strong>
          <span className="movie-card__details-value">{duration}</span>
        </p>
        <p className="movie-card__details-item">
          <strong className="movie-card__details-name">Genre</strong>
          <span className="movie-card__details-value">{genre}</span>
        </p>
        <p className="movie-card__details-item">
          <strong className="movie-card__details-name">Released</strong>
          <span className="movie-card__details-value">{released}</span>
        </p>
      </div>
    </div>
  );
}
export default Details;
